import React from "react";

import Layout from "../components/Layout";
import { getIntl } from "../helpers/rioti18n";
import RiotBar from "@riotgames/riotbar-react";
import { PageProps } from "gatsby";
import { LangLocale } from "../helpers/rioti18n";

export interface ErrorPageProps {}

export interface ErrorPageData {
  language: any;
}

export interface ErrorPageContentstackProps extends PageProps<ErrorPageProps, ErrorPageData> {}

const NotFoundPage = (props: ErrorPageContentstackProps) => {
  const { t } = getIntl();
  let language = props.pageResources?.json?.pageContext.language || "en-us";
  let env = process.env.CONTENTSTACK_ENVIRONMENT || "staging";
  let environment = "production";
  if (["staging", "test", "local", ""].includes(env)) {
    environment = "test";
  }

  return (
    <Layout>
      <RiotBar product="tellstones" environment={environment} locale={LangLocale[language]} />
      <div style={{ padding: "68px" }}>
        <h1 data-testid="404:title">{t("404.notFound")}</h1>
        <p data-testid="404:description">{t("404.message")}</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
